import React from 'react';
import moment from 'moment';
import { createSelector } from 'reselect';
import styled from 'styled-components';

import { getDollarAndCentsFromCents, getDollarAndCentsFromDollars } from 'lib/utils';

export const Sup = styled.sup`
  font-size: ${(props) => (props.jumbo ? '2rem' : '0.68rem')};
  padding: ${(props) => (props.jumbo ? '0 4px' : '0 2px')};
`;

const StyledFormattedMoney = styled.span`
  color: ${(props) => (props.status === 'next-payment' ? props.theme.colors.upliftTeal : '')};
  color: ${(props) => (props.status === 'overdue' ? props.theme.colors.error : '')};
`;

export const formatAPR = createSelector(
  (amount) => amount,
  (amount) => {
    // revisit: this should be an integer from the backend && use 'getDollarAndCentsFromCents()'
    const percentage = amount / 100;

    return <>{percentage}%</>;
  }
);

export const formatDate = createSelector(
  (americanDate) => americanDate,
  (AmericanDate) => {
    return moment(AmericanDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
  }
);

export const formatExpiryDate = createSelector(
  (month) => month,
  (_, year) => year,
  (month, year) => {
    if (!month || !year) {
      return 'N/A';
    }
    const shortYear = year % 100;
    return `${month}/${shortYear}`;
  }
);

export const formatMoneyFromCents = createSelector(
  (amountInCents) => amountInCents,
  (_, jumbo) => jumbo,
  (a, j, language) => language,
  (amountInCents, jumbo = false, language) => {
    if (Number.isNaN(amountInCents) || amountInCents === undefined || amountInCents === null) {
      // eslint-disable-next-line no-param-reassign
      amountInCents = 0;
    }

    const [dollars, cents] = getDollarAndCentsFromCents(amountInCents);

    return language?.startsWith('fr') ? (
      <>
        {dollars}
        <Sup jumbo={jumbo}>{cents}</Sup>
        <Sup jumbo={jumbo}>$</Sup>
      </>
    ) : (
      <>
        <Sup jumbo={jumbo}>$</Sup>
        {dollars}
        <Sup jumbo={jumbo}>{cents}</Sup>
      </>
    );
  }
);

export const formatStyledMoneyFromCents = createSelector(
  (amountInCents) => amountInCents,
  (_, status) => status,
  (amountInCents, status = '') => {
    if (Number.isNaN(amountInCents) || amountInCents === undefined || amountInCents === null) {
      // eslint-disable-next-line no-param-reassign
      amountInCents = 0;
    }

    const [dollars, cents] = getDollarAndCentsFromCents(amountInCents);

    return (
      <StyledFormattedMoney status={status}>
        <Sup>$</Sup>
        {dollars}
        <Sup>{cents}</Sup>
      </StyledFormattedMoney>
    );
  }
);

// export const formatMoneyFromDollars = createSelector(
//   (amountInDollars) => amountInDollars,
//   (amountInDollars) => {
//     if (Number.isNaN(amountInDollars) || amountInDollars === undefined || amountInDollars === null) {
//       // eslint-disable-next-line no-param-reassign
//       amountInDollars = 0;
//     }
//     const [dollars, cents] = getDollarAndCentsFromDollars(amountInDollars);

//     return (
//       <>
//         <Sup>$</Sup>
//         {dollars}
//         <Sup>{cents}</Sup>
//       </>
//     );
//   }
// );

export const formatMoneyDecimalFromDollars = createSelector(
  (amountInDollars) => amountInDollars,
  (amountInDollars) => {
    if (Number.isNaN(amountInDollars) || amountInDollars === undefined || amountInDollars === null) {
      return amountInDollars;
    }

    const [dollars, cents] = getDollarAndCentsFromDollars(amountInDollars);

    return (
      <>
        <Sup>$</Sup>
        {dollars}.{cents}
      </>
    );
  }
);

export const formatMoneyDecimalFromCents = createSelector(
  (amountInCents) => amountInCents,
  (amountInCents) => {
    if (Number.isNaN(amountInCents) || amountInCents === undefined || amountInCents === null) {
      // eslint-disable-next-line no-param-reassign
      amountInCents = 0;
    }

    const [dollars, cents] = getDollarAndCentsFromCents(amountInCents);

    return (
      <>
        <Sup>$</Sup>
        {dollars}.{cents}
      </>
    );
  }
);

export const formatDecimalPlainFromCents = createSelector(
  (amountInCents) => amountInCents,
  (amountInCents) => {
    if (Number.isNaN(amountInCents) || amountInCents === undefined || amountInCents === null) {
      // eslint-disable-next-line no-param-reassign
      amountInCents = 0;
    }

    const [dollars, cents] = getDollarAndCentsFromCents(amountInCents);

    return `${dollars}.${cents}`;
  }
);

export const formatMoneyDecimalPlainFromCents = createSelector(
  (amountInCents) => amountInCents,
  (amountInCents) => {
    if (Number.isNaN(amountInCents) || amountInCents === undefined || amountInCents === null) {
      // eslint-disable-next-line no-param-reassign
      amountInCents = 0;
    }

    const [dollars, cents] = getDollarAndCentsFromCents(amountInCents);

    return `$${dollars}.${cents}`;
  }
);

export const formatMaskedCreditCardNumber = createSelector(
  (maskedCC) => maskedCC,
  (maskedCC) => {
    return maskedCC ? maskedCC.substr(maskedCC.length - 4) : null;
  }
);

export const formatMaskedCreditCardNumberWithBullets = createSelector(
  (maskedCC) => maskedCC,
  (maskedCC) => {
    return maskedCC ? `••••${maskedCC.substr(maskedCC.length - 4)}` : null;
  }
);

export const formatLastFourDigits = createSelector(
  (accountNumber) => accountNumber,
  (accountNumber) => (accountNumber && accountNumber.length >= 4 ? accountNumber.substr(accountNumber.length - 4) : accountNumber)
);

export const formatPhoneNumber = (phoneNumber, includeCountryDigit = false) => {
  const re = /[^0-9.]/g;
  let sanitizedPhoneNumber = phoneNumber.replace(re, '');

  let countryCode = '';
  if (includeCountryDigit && sanitizedPhoneNumber.length === 11) {
    countryCode = sanitizedPhoneNumber.charAt(0);
    sanitizedPhoneNumber = sanitizedPhoneNumber.substr(1);
  }

  const npa = sanitizedPhoneNumber.substr(0, 3);
  const nxx = sanitizedPhoneNumber.substr(3, 3);
  const xxxx = sanitizedPhoneNumber.substr(6, 4);

  sanitizedPhoneNumber = `${countryCode ? `+${countryCode} ` : ''}(${npa}) ${nxx}${xxxx.length > 0 ? ' - ' : ''}${xxxx}`;

  return sanitizedPhoneNumber;
};

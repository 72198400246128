import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import OutboundLink from 'analytics/components/OutboundLink';

import LanguageSelector from 'components/LanguageSelector';
import { getMigrationStatus, isCanadianSelector } from 'state/account/selectors';
import { documentsSelector } from 'state/documents/selectors';

import { MaterialIcon } from 'styles/Icons';
import { MIGRATION_STATUS } from 'lib/constants/migrationStatuses';
import { getCurrentYear } from 'lib/utils';

const StyledFooter = styled.div`
  visibility: ${(props) => (props.isMigrating ? 'hidden' : 'visible')};
  background-color: ${(props) => props.theme.colors.black};
  color: #fff;
  font-size: 0.75em;
  width: 100%;
  padding-bottom: 5rem;
  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    padding-bottom: 0rem;
    min-height: 72px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0px;
  padding: 10px;
`;

const ListItem = styled.li`
  list-style-type: none;
  padding: 10px 10px;
  & > a {
    color: #fff !important;
  }
  text-decoration: underline;
  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    & > a:hover {
      color: #fff !important;
    }
    text-decoration: none;
  }
`;

const StyledLink = styled(NavLink)`
  padding: 10px 10px;
  color: #fff !important;
  text-decoration: underline;
  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    text-decoration: none;
    &:hover {
      color: #fff !important;
    }
  }
`;

const LanguageBar = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: center;
`;

const Copyright = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 0.65rem;
  padding-bottom: 1rem;
  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    padding-bottom: 0.65rem;
  }
`;

const iconSize = '1.2rem';

const canadianDocs = { ach: 'ach_CA', cpa: 'cpa_CA', dcd: 'dcd_CA', til: 'til_CA' };
const languageParams = { 'en-US': '', en: '/en', fr: '/fr', es: '/es' };

const Footer = memo(() => {
  const { t, i18n } = useTranslation();

  const isCanadian = useSelector(isCanadianSelector);
  const migrationStatus = useSelector(getMigrationStatus);
  const currentLanguage = i18n.language;
  const currentYear = getCurrentYear();
  const upliftUrl = (path) => {
    return languageParams[currentLanguage]
      ? `https://www.uplift.com${languageParams[currentLanguage]}/${path}`
      : `https://www.uplift.com/${path}`;
  };

  // Commented out until we get confirmation we want to change
  // these footer links as well
  // const upgradeUrl = (path) => {
  //   return `https://www.upgrade.com/flex-pay/${path}`;
  // };

  const documents = useSelector(documentsSelector);

  return (
    <StyledFooter id="Footer" isMigrating={migrationStatus === MIGRATION_STATUS.MIGRATING}>
      <List>
        <ListItem>
          <OutboundLink eventLabel="terms-of-use" to={upliftUrl('terms')} target="_blank" rel="noopener">
            {t('documents.tos')}
          </OutboundLink>
        </ListItem>
        <ListItem>
          <OutboundLink eventLabel="privacy-policy" to={upliftUrl('privacy')} target="_blank" rel="noopener">
            {t('documents.pp')}
          </OutboundLink>
        </ListItem>
        {documents &&
          documents
            .filter((doc) => ['ach', 'cpa', 'dcd', 'esign', 'cip'].includes(doc.documentType))
            .filter((doc) => !(doc.documentType === 'dcd' && !isCanadian))
            .sort((a, b) => (a.documentType > b.documentType ? 1 : -1))
            .map((doc) => (
              <StyledLink to={`/account/document/${doc.documentType}#${doc.documentType}`} key={doc.documentType}>
                {t(`documents.${(isCanadian && canadianDocs[doc.documentType]) || doc.documentType}`)}
              </StyledLink>
            ))}
      </List>
      <LanguageBar>
        <MaterialIcon name="public" size={iconSize} />
        &nbsp;
        <LanguageSelector />
      </LanguageBar>
      <Copyright>
        <div>
          <Trans i18nKey={`authentication.footer.copyright${isCanadian ? '_CA' : ''}`} year={getCurrentYear()}>
            © {{ year: currentYear }}, <span>Upgrade Inc.</span>
          </Trans>
        </div>
      </Copyright>
    </StyledFooter>
  );
});

Footer.displayName = 'Footer';

export default Footer;

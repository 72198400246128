import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Loader from 'components/Loader';
import upliftLogo from 'lib/assets/images/uplift-logo.png';
import { MaterialIcon } from 'styles/Icons';
import { Alert, ButtonLink, IconColorHyperlinkBlue, LoaderContainer } from 'styles/CommonStyles';

import { accountSelector, isCanadianSelector } from 'state/account/selectors';
import { loanSelector } from 'state/loans/selectors';
import { calculatePayoffSelector } from 'state/loans/calculatePayoff/selectors';
import calculatePayoff from 'state/loans/calculatePayoff/actions';
import { convertAmountInCentsToDollarsString, printStatement } from 'lib/utils';
import { trackAll } from 'analytics/tracking';
import { UPLIFT_CONTACT_INFO } from 'lib/constants/servicersInfo';

const StyledLink = styled(NavLink)`
  display: flex;

  color: ${(props) => props.theme.colors.hyperlinkBlue};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.hyperlinkBlueActive};
    text-decoration: none;
  }
`;

const StyledLetter = styled.div`
  margin: 0 auto;
  max-width: 800px;
  width: 90%;
`;

const SubHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1rem 3rem;
`;

const HideOnScreen = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;

const PayoffLetterView = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isCanadian = useSelector(isCanadianSelector);
  const locale = i18n.language === 'fr' ? 'FR' : '';
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const numDays = parseInt(searchParams.get('days'), 10);

  const { loanId } = useParams();
  const loan = useSelector((state) => loanSelector(state, loanId));
  const account = useSelector(accountSelector);
  const payoffCalculation = useSelector(calculatePayoffSelector);

  useEffect(() => {
    if (loan && !payoffCalculation.data && !payoffCalculation.error) {
      dispatch(calculatePayoff(loan.loanId, numDays));
    }
  }, [dispatch, loan, numDays, payoffCalculation.error, payoffCalculation.data]);

  moment.locale(i18n.language);
  const todaysDate = moment().format('MMMM DD, YYYY');
  const todaysDateLong = moment().format('MM/DD/YYYY');
  const payoffDate = moment()
    .add(numDays, 'days')
    .format('MM/DD/YYYY');

  const onPrint = useCallback(() => {
    trackAll({
      category: 'user',
      action: 'click',
      label: 'print-payoff-letter',
      value: 1,
    });
    printStatement();
  }, []);

  const mailingAddress = useMemo(() => {
    if (isCanadian) {
      if (locale === 'FR') {
        return UPLIFT_CONTACT_INFO.mailingAddressCAFR;
      }
      return UPLIFT_CONTACT_INFO.mailingAddressCA;
    }
    return UPLIFT_CONTACT_INFO.mailingAddressUS;
  }, [isCanadian, locale]);

  if (!loan || payoffCalculation.loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  const formattedCurrentBalance = `$${convertAmountInCentsToDollarsString(loan?.currentPayoffBalance || 0)}`;
  const formattedPayoffAmount = `$${convertAmountInCentsToDollarsString(payoffCalculation?.data?.amount || 0)}`;
  const formattedPerdiem = `$${loan?.currentPerdiem ? parseFloat(loan.currentPerdiem) : 0}`;
  return (
    <>
      <SubHeader>
        <StyledLink to="/loans">
          <MaterialIcon name="chevron_left" size="1.5rem" color={IconColorHyperlinkBlue} />
          <div data-testid="navigation-loans">{t('header.navigation.loans')}</div>
        </StyledLink>
        <ButtonLink onClick={onPrint}>
          <div data-testid="print">{t('common.buttons.print')}</div>&nbsp;
          <MaterialIcon name="print" size="1.2rem" color={IconColorHyperlinkBlue} />
        </ButtonLink>
      </SubHeader>

      <StyledLetter className="container">
        {payoffCalculation.error ? (
          <Alert variant="danger">{t('common.errors.generic')}</Alert>
        ) : (
          <>
            <HideOnScreen>
              <img className="mb-5" alt="Uplift Logo" src={upliftLogo} style={{ width: '120px' }} />
            </HideOnScreen>
            <h4>{t('documents.payoff_letter.template.payoff_statement_date', { payoffDate })}</h4>
            <div className="text-muted">{todaysDate}</div>
            <p className="py-4 mb-0 lead">{t('documents.payoff_letter.template.salutation')}</p>
            <div>
              <p>
                {t('documents.payoff_letter.template.regarding', {
                  firstName: account?.primaryContact?.givenName,
                  lastName: account?.primaryContact?.lastName,
                  loanId,
                })}
              </p>
              <p>{t('documents.payoff_letter.template.payment_instructions')}</p>
            </div>
            <div className="pt-3 pb-5">
              <table>
                <tbody>
                  <tr>
                    <td className="pr-4 py-3">
                      {t('documents.payoff_letter.template.current_balance', { dueDate: todaysDateLong })}
                    </td>
                    <td className="pl-4 py-3">{formattedCurrentBalance}</td>
                  </tr>
                  <tr>
                    <td className="pr-4 py-3">
                      <u>{t('documents.payoff_letter.template.interest_per_diem')}</u>
                    </td>
                    <td className="pl-4 py-3">
                      <u>{formattedPerdiem}</u>
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-4 py-3 font-weight-bold">
                      {t('documents.payoff_letter.template.x_day_payoff_amount', { numDays })}
                    </td>
                    <td className="pl-4 py-3 font-weight-bold">{formattedPayoffAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <p>
                <b>{t('documents.payoff_letter.template.checks_instructions')}</b>
                <br />
                <b>{t('documents.payoff_letter.template.mailing_address', { address: mailingAddress })}</b>
              </p>
              <p>
                <Trans i18nKey="documents.payoff_letter.template.contact_us" values={{ email: UPLIFT_CONTACT_INFO.supportEmail }}>
                  If you need any additional information, please feel free to contact our office at 844-257-5400 or email
                  <a href={`mailto:${UPLIFT_CONTACT_INFO.supportEmail}`}>{UPLIFT_CONTACT_INFO.supportEmail}</a>.
                </Trans>
              </p>
            </div>
            <div className="py-5">
              <div>{t('documents.payoff_letter.template.sign_off')}</div>
              <div>{UPLIFT_CONTACT_INFO.upliftSignature}</div>
            </div>
          </>
        )}
      </StyledLetter>
    </>
  );
};

export default PayoffLetterView;

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from 'styles/CommonStyles';
import CarnivalLogo from 'lib/assets/images/carnival-logo.svg';
import CarnivalMailImg from 'lib/assets/images/carnival-banner-mail.svg';
import CarnivalDealImg from 'lib/assets/images/carnival-banner-deal.svg';
import { carnivalBannerValidLoans } from 'state/loans/selectors';
import { isValidAccountForCCLBanner } from 'state/account/selectors';
import VerticalDivider from 'lib/assets/images/vertical-divider.svg';
import OutboundLink from 'analytics/components/OutboundLink';

const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  width: 90%;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: 0rem 0.25rem 0.625rem 0rem rgba(58, 59, 65, 0.16);
  font-family: ${(props) => props.theme.font.fontFamily.carnivalBanner} !important;

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    justify-content: space-between;
    width: 45.75rem;
  }
  @media screen and (min-width: ${(props) => props.theme.minWidth.pico}) {
    width: 28rem;
    margin: 0 auto 20px;
  }
  @media screen and (min-width: ${(props) => props.theme.minWidth.nano}) {
    width: 37.75rem;
  }
  @media screen and (min-width: ${(props) => props.theme.minWidth.milli}) {
    width: 45.75rem;
    gap: 40px;
    margin-bottom: 20px;
  }
`;

const BlueBanner = styled(BannerContainer)`
  background-color: ${(props) => props.theme.colors.carnivalBannerBlue};
  flex-direction: column;
  gap: 0.5rem;
  padding: 12px 28px;
  margin-bottom: 5px;
  @media screen and (min-width: ${(props) => props.theme.minWidth.milli}) {
    flex-direction: row;
    padding: 16px 16px;
  }
`;

const WhiteBanner = styled(BannerContainer)`
  background-color: ${(props) => props.theme.colors.upliftWhite};
  height: 60px;
  padding: 14px 14.586px 14px 14px;

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    margin-bottom: 20px;
    padding: 1rem 1.25rem;
    height: 40px;
    gap: 0px !important;
    justify-content: space-around;
  }
`;

const FooterText = styled.span`
  color: ${(props) => props.theme.colors.bannerFooter};
  font-family: ${(props) => props.theme.font.fontFamily.carnivalBanner} !important;
  font-weight: ${(props) => (props.isBold ? '700' : '600')};
  font-size: 12.5px;
  line-height: 130%;
  padding-left: 8px;
  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    font-size: 0.78125rem;
    line-height: 120%;
  }
`;

const StyledImg = styled.img`
  width: 1.328rem;
  height: 1.328rem;
  transform: rotate(-10deg);
  flex-shrink: 0;
`;

const BigTextHeader = styled.span`
  color: ${(props) => props.theme.colors.upliftWhite};
  font-family: ${(props) => props.theme.font.fontFamily.carnivalBanner};
  font-size: 21px;
  font-weight: 800;
  line-height: 104%;
  text-transform: uppercase;
`;

const ClaimOfferButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.carnivalBannerButtonRed};
  text-transform: uppercase;
  font-family: ${(props) => props.theme.font.fontFamily.carnivalBanner};
  color: #fff;
  border-radius: 0.1715rem;
  font-size: 0.7945rem;
  font-weight: 800;
  white-space: nowrap;
  &:hover {
    background-color: ${(props) => props.theme.colors.carnivalBannerButtonRed};
    border: 0.0625rem solid ${(props) => props.theme.colors.carnivalBannerButtonRed};
    color: ${(props) => props.theme.colors.upliftWhite};
  }
`;

const StyledNumbers = styled.img`
  max-width: 20rem;
  max-height: 6rem;
  flex-shrink: 1;
  @media screen and (min-width: ${(props) => props.theme.minWidth.milli}) {
    width: 223.446px;
    height: 68.131px;
  }
`;

const BannerTextWithLogo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  @media screen and (min-width: ${(props) => props.theme.minWidth.milli}) {
    flex-direction: row;
  }
`;

const StyledBannerLogo = styled.img`
  height: 2.16375rem;

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    width: 7.25rem;
    height: 1.763rem;
  }
`;

const TermsAndConditions = styled.span`
  color: ${(props) => props.theme.colors.upliftWhite};
  font-family: ${(props) => props.theme.font.fontFamily.carnivalBanner};
  font-size: 0.3125rem;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
  width: 90%;
  white-space: nowrap;

  @media screen and (min-width: ${(props) => props.theme.minWidth.micro}) {
    white-space: wrap;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3.78px;
  @media screen and (min-width: ${(props) => props.theme.minWidth.milli}) {
    align-self: flex-end;
    padding-left: 30px;
  }
`;

const StyledVerticalDivider = styled.img`
  height: 41.816px;
  flex-shrink: 0;
  @media screen and (max-width: ${(props) => props.theme.minWidth.micro}) {
    display: none;
  }
`;

const CarnivalBanner = () => {
  const { t } = useTranslation();
  const validLoans = useSelector(carnivalBannerValidLoans);
  const validAccount = useSelector(isValidAccountForCCLBanner);

  if (!validLoans || !validAccount) {
    return null;
  }

  return (
    <>
      <BlueBanner>
        <BannerTextWithLogo>
          <StyledBannerLogo src={CarnivalLogo} />
          <StyledVerticalDivider src={VerticalDivider} />
          <BigTextHeader>{t('banner.carnival_banner.exclusive_cruise_offer')}</BigTextHeader>
        </BannerTextWithLogo>
        <StyledNumbers alt="Up to 40% Off Cruise Rates with 200$ Credit and 2-For-1 Deposits" src={CarnivalDealImg} />
        <ButtonContainer>
          <a
            href="https://www.carnival.com/registration/promotions/flexpay-2025?utm_source=flexpay&utm_medium=borrower_portal&utm_campaign=flexpay_instant_tgo&utm_content=022020205"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ClaimOfferButton>{t('banner.carnival_banner.claim_offer')}</ClaimOfferButton>
          </a>
          <TermsAndConditions>
            <OutboundLink
              eventLabel="big-uplift-campaign-info"
              to="https://www.carnival.com/cruise-deals/vifp-club/past-guest-offers-o5u/terms"
              target="_blank"
              rel="noopener"
            >
              <TermsAndConditions>{t('banner.carnival_banner.terms_and_conditions')}</TermsAndConditions>
            </OutboundLink>
          </TermsAndConditions>
        </ButtonContainer>
      </BlueBanner>
      <WhiteBanner>
        <StyledImg src={CarnivalMailImg} />
        <FooterText>
          <Trans i18nKey="banner.carnival_banner.exclusive">
            To get this exclusive offer, you&apos;ll need to sign up for or sign in to Carnival&apos;s VIFP program.
            <b>It&apos;s free to join!</b>
          </Trans>
        </FooterText>
      </WhiteBanner>
    </>
  );
};

export default CarnivalBanner;

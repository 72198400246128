import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Wrapper from 'styles/Wrapper';
import StyledContainer from 'styles/Container';
import FlexGrow from 'styles/FlexGrow';
import { getMigrationStatus } from 'state/account/selectors';

import Banner from 'components/banners/Banner';
import BigUpliftBanner from 'components/banners/BigUpliftBanner';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MissingPaymentMethodBanner from './banners/MissingPaymentMethodBanner';
import FlexPayBanner from './banners/FlexpayBanner';
import OnboardSpendDocumentBanner from './banners/OnboardSpendDocumentBanner';
import CarnivalBanner from './banners/CarnivalBanner';

const GlobalContainer = memo((props) => {
  const content = props.children || props.content;
  const migrationStatus = useSelector(getMigrationStatus);

  const displayBigUpliftBanner = process.env.REACT_APP_DISPLAY_BIG_UPLIFT_CAMPAIGN_BANNER === 'true';
  const displayCarnivalBanner = process.env.REACT_APP_CARNIVAL_BANNER_PROMOTION === 'true';

  return (
    <main role="main">
      <Wrapper id="IE Wrapper">
        <StyledContainer id="Container" isWhiteBackground={props.isWhiteBackground} isMigrated={props.isMigrated}>
          {displayBigUpliftBanner && <BigUpliftBanner />}
          <Banner />
          <MissingPaymentMethodBanner />
          <OnboardSpendDocumentBanner />
          <Header />
          {!migrationStatus && <FlexPayBanner />}
          {displayCarnivalBanner && <CarnivalBanner />}

          {content}

          <FlexGrow id="FlexGrow" />
          <Footer />
        </StyledContainer>
      </Wrapper>
    </main>
  );
});

GlobalContainer.displayName = 'GlobalContainer';

GlobalContainer.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  isWhiteBackground: PropTypes.bool,
  isMigrated: PropTypes.bool,
};

GlobalContainer.defaultProps = {
  children: null,
  content: null,
  isWhiteBackground: false,
  isMigrated: false,
};

export default GlobalContainer;

import { createSelector } from 'reselect';
import moment from 'moment';
import { EXCLUDE_UPCODES_REGEX, CARNIVAL_UPCODES } from '../../lib/constants/carnivalUpcodes';

export * from './delayPayment/selectors';
export * from './makePayment/selectors';

export const hasLoansSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.count > 0
);

export const loansSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.data
);

export const loansLoadingSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.loading
);

export const loanSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, loanId) => state.loansReducer && state.loansReducer.data,
  (state, loanId) => loanId,
  (data, loanId) => data && data.find((value) => value.loanId === loanId)
);

export const failedDownpaymentLoansSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.data?.filter((x) => x.isDownPaymentMissing === true)
);

export const autopayDisabledLoansSelector = createSelector(
  (state) => state.loansReducer,
  (loansReducer) => loansReducer.data?.filter((x) => x.autopay === false)
);

export const associatedPaymentMethodLoanSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, paymentId) => state.loansReducer && state.loansReducer.data,
  (state, paymentId) => paymentId,
  (data, paymentId) => {
    const associatedPaymentMethods = data?.filter((loan) => loan.paymentToken === paymentId);

    if (associatedPaymentMethods?.length > 0) {
      return associatedPaymentMethods;
    }

    return [];
  }
);

export const loanNextPaymentDateSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, loanId) => state.loansReducer && state.loansReducer.data,
  (state, loanId) => loanId,
  (data, loanId) => {
    const loan = data && data.find((value) => value.loanId === loanId);
    if (loan) {
      const nextPayment = loan.paymentHistory.find((value) => value.status === 'Next Payment');
      if (nextPayment) {
        return nextPayment.date;
      }
    }
    return false;
  }
);

export const loanDocumentsSelector = createSelector(
  // eslint-disable-next-line no-unused-vars
  (state, loanId) => state.loansReducer && state.loansReducer.data,
  (state, loanId) => loanId,
  (data, loanId) => data && data.find((value) => value.loanId === loanId).loanDocuments
);

export const loansClosedSelector = createSelector(
  (state) => state.loansReducer && state.loansReducer.data,
  (data) => data && data.filter((value) => value.state === 'CLOSED').length === data.length
);

export const loansHasPendingRefundSelector = createSelector(
  (state) => state.loansReducer && state.loansReducer.data,
  // data => data && data.find(value => value.pendingRefund === true).pendingRefund
  (data) => {
    const loan = data && data.find((value) => value.pendingRefund === true);
    if (loan) {
      return true;
    }
    return false;
  }
);

export const loansWithoutPaymentMethodsSelector = createSelector(
  (state) => state.loansReducer && state.loansReducer.data,
  (data) => data?.filter((loan) => loan?.state?.toUpperCase() === 'ACTIVE' && !loan?.paymentToken)
);

export const carnivalBannerValidLoans = createSelector(
  (state) => state?.loansReducer?.data,
  (data) => {
    if (!data || data.length === 0) {
      return false;
    }
    let hasInvalidCarnivalLoan = false;
    const cutoffDate = moment('2025-02-01').utcOffset('-08:00'); // Use PST
    const filteredLoans = data.filter((loan) => {
      const isCarnivalLoan = CARNIVAL_UPCODES.has(loan.upcode);
      const hasValidTripStartDate = loan.order?.cruiseReservations?.some((reservation) =>
        moment(reservation?.embarkDate).isBefore(cutoffDate)
      );
      if (isCarnivalLoan) {
        if (hasValidTripStartDate) {
          return true;
        }
        hasInvalidCarnivalLoan = true;
        return false;
      }
      // Exclude any loans that are in the EXCLUDE_UPCODES_REGEX list
      if (EXCLUDE_UPCODES_REGEX.some((regex) => regex.test(loan.upcode))) {
        return false;
      }
      // All other loans are valid
      return true;
    });
    // If there is at least one valid loan, we want to display the banner.
    // If there is ANY invalid CCL loan, don't display banner.
    return filteredLoans.length > 0 && !hasInvalidCarnivalLoan;
  }
);

export const CARNIVAL_UPCODES = new Set(['UP-20956569-1', 'UP-20956569-3']);

export const EXCLUDE_UPCODES_SET = new Set([
  'UP-43928860-X',
  'UP-32535293-X',
  'UP-44195078-X',
  'UP-12766233-X',
  'UP-70216241-X',
  'UP-96591218-X',
  'UP-69687786-X',
  'UP-13566758-X',
  'UP-80155285-X',
  'UP-13686392-1',
  'UP-14199135-X',
  'UP-17247267-X',
  'UP-20956569-51',
  'UP-21172612-3',
  'UP-21172612-X',
  'UP-29881480-X',
  'UP-29949502-X',
  'UP-30844742-X',
  'UP-34161755-X',
  'UP-38681422-X',
  'UP-39732680-X',
  'UP-39789824-X',
  'UP-51133962-X',
  'UP-53344583-X',
  'UP-58906455-X',
  'UP-64546641-X',
  'UP-64814223-X',
  'UP-70612684-X',
  'UP-71166027-X',
  'UP-71166027-X',
  'UP-79934337-52',
  'UP-79934337-53',
  'UP-79934337-61',
  'UP-79934337-56',
  'UP-79934337-54',
  'UP-79934337-55',
  'UP-79934337-X',
  'UP-84458467-X',
  'UP-88032940-X',
  'UP-93079578-X',
  'UP-94750867-X',
]);

export const EXCLUDE_UPCODES_REGEX = Array.from(EXCLUDE_UPCODES_SET).map(
  (pattern) => new RegExp(`^${pattern.replace('X', '\\d')}$`)
);
